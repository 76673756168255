import { ContentJob, Glossary, Suggestion, TaskContent } from '.';

export class Task implements ProjectTask {
  readonly id: number = null;
  readonly content_job_id: number | null;
  readonly created_at: string;
  readonly creditable_performance?: number;
  readonly deleted_at: string | null;
  readonly delivered_at: string | null;
  readonly destination_language_tag_code: string;
  readonly due_date: string | null;
  readonly earning: number;
  readonly finished_at: string | null;
  readonly is_edit: number;
  readonly name: string;
  readonly priority: number;
  readonly project_id: number;
  readonly responsible_user_id: number | null;
  readonly source_language_tag_code: string;
  readonly status: ProjectTaskStatus;
  readonly text_character_count?: number;
  readonly text_word_count?: number;
  readonly translator_user_id: number | null;
  readonly updated_at: string;
  readonly article_exists: boolean;
  readonly project?: Project;
  readonly responsible?: User;
  readonly skipped?: TaskSkipped[];
  readonly translator?: User;
  readonly latest?: boolean;
  suggestions: {
    isLoading?: boolean;
    // for which segments were the suggestions loaded
    origin?: HTMLElement;
    suggestions: Suggestion[];
  } = {
    isLoading: false,
    suggestions: [],
  };
  glossaries: { isLoading?: boolean; glossaries: Glossary[] } = {
    isLoading: false,
    glossaries: [],
  };

  readonly contentJob: ContentJob = null;
  content: TaskContent = null;

  preventSaving = 0;
  preventModifying = 0;

  static createEmpty(): Task {
    return new this({
      task: {
        id: null,
        content_job_id: 0,
        created_at: '2023-03-02T05:19:05.000000Z',
        creditable_performance: 0,
        deleted_at: null,
        delivered_at: '2023-03-02T05:19:05.000000Z',
        destination_language_tag_code: 'xx',
        due_date: '2023-03-02T05:19:05.000000Z',
        earning: null,
        finished_at: null,
        is_edit: 0,
        name: '',
        priority: 0,
        project_id: 1,
        responsible_user_id: 0,
        responsible: {} as User,
        source_language_tag_code: 'xx',
        status: 'finished',
        text_character_count: 0,
        text_word_count: 0,
        translator_user_id: null,
        translator: {} as User,
        updated_at: '2023-03-02T05:19:05.000000Z',
        article_exists: true,
        skipped: [
          {
            id: null,
            task_id: null,
            user_id: null,
            user: {} as User,
            description: '',
            deleted_at: '',
            created_at: '2023-03-02T05:19:05.000000Z',
            updated_at: '2023-03-02T05:19:05.000000Z',
          },
        ],
      },
      content: TaskContent.createEmpty(),
      contentJob: ContentJob.createEmpty(),
      suggestions: {
        isLoading: true,
        suggestions: new Array(3)
          .fill(null)
          .map(() => Suggestion.createEmpty()),
      },
      glossaries: {
        isLoading: true,
        glossaries: new Array(3).fill(null).map(() => Glossary.createEmpty()),
      },
    });
  }

  constructor({
    task,
    contentJob,
    content,
    suggestions = { suggestions: [] },
    glossaries = { glossaries: [] },
    latest = false,
  }: {
    task: ProjectTask;
    contentJob: ContentJob;
    content: TaskContent;
    suggestions: {
      isLoading?: boolean;
      origin?: HTMLElement;
      suggestions: Suggestion[];
    };
    glossaries: { isLoading?: boolean; glossaries: Glossary[] };
    latest?: boolean;
  }) {
    this.id = task.id;
    this.content_job_id = task.content_job_id;
    this.created_at = task.created_at;
    this.creditable_performance = task.creditable_performance;
    this.deleted_at = task.deleted_at;
    this.delivered_at = task.delivered_at;
    this.destination_language_tag_code = task.destination_language_tag_code;
    this.due_date = task.due_date;
    this.earning = task.earning;
    this.finished_at = task.finished_at;
    this.is_edit = task.is_edit;
    this.name = task.name;
    this.priority = task.priority;
    this.project_id = task.project_id;
    this.responsible_user_id = task.responsible_user_id;
    this.source_language_tag_code = task.source_language_tag_code;
    this.status = task.status;
    this.text_character_count = task.text_character_count;
    this.text_word_count = task.text_word_count;
    this.translator_user_id = task.translator_user_id;
    this.updated_at = task.updated_at;
    this.article_exists = task.article_exists || false;
    this.project = task.project;
    this.responsible = task.responsible;
    this.skipped = task.skipped;
    this.translator = task.translator;
    this.contentJob = contentJob;
    this.content = content;
    this.suggestions = suggestions;
    this.glossaries = glossaries;
    this.latest = latest;
  }

  /**
   * Disallow saving manually
   *
   * this works in pair with allowSave()
   * it increases value by preventingSave and decreases it by allowSave
   * this behaviour can be also seen as transaction / or a stack
   *
   * @note this is not the only condition
   *
   */
  preventSave(): void {
    this.preventSaving += 1;
  }

  /**
   * Allow saving manually
   *
   * this works in pair with preventSave()
   * it decreases value by preventingSave and decreases it by allowSave
   * this behaviour can be also seen as transaction / or a stack
   *
   * @note this is not the only condition
   */
  allowSave(): void {
    const next = this.preventSaving - 1;

    if (next < 0) {
      return;
    }

    this.preventSaving = next;
  }

  /**
   * similar to preventSave
   * but about typing, drag n drop
   */
  preventModify(): void {
    this.preventModifying += 1;
  }

  /**
   * similar to allowSave
   * but about typing, drag n drop
   */
  allowModify(): void {
    const next = this.preventModifying - 1;

    if (next < 0) {
      return;
    }

    this.preventModifying = next;
  }

  get isFinished(): boolean {
    return this.status === 'finished';
  }

  get isSkipped(): boolean {
    return this.status === 'skipped';
  }

  get isDelayed(): boolean {
    return this.status === 'delayed';
  }

  get isUnderReview(): boolean {
    return this.status === 'under-review';
  }

  get isDisabled(): boolean {
    return this.isFinished || this.isSkipped || this.isDelayed;
  }

  get isCancelable(): boolean {
    return (
      typeof this.content.meta?.initial_source === 'object' &&
      this.content.meta.initial_source !== null
    );
  }

  get isEditable(): boolean {
    return !this.isSkipped && !this.isDelayed;
  }

  get isUpdatable(): boolean {
    if (!this.isEditable) {
      return false;
    }

    if (!this.isFinished) {
      return true;
    }

    return this.latest;
  }
}
