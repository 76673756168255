import { useDebounce, useNotificationContext } from '@/composables';
import {
  ContentJob,
  Glossary,
  Suggestion,
  Task,
  TaskContent,
  TaskSegment,
  TaskSegments,
} from '@/modules/articles/entities';
import { TaskContentService } from '@/services';
import { Logger } from '@/utils';
import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { Ref, ref, UnwrapRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export const useTaskStore = defineStore('task', () => {
  const task = ref<Task>(Task.createEmpty());
  const isTaskDetailLoading = ref(true);
  const articleNo = ref<string>();
  const canEditTask = ref<boolean>(false);

  const languages = ref<SelectableItem<string>[]>();
  const selectedSegment = ref<{
    input: HTMLElement;
    source: TaskSegment;
    destination: TaskSegment;
  }>();
  const { t } = useI18n();
  const notification = useNotificationContext();
  const debounce = useDebounce();

  async function splitSegment(
    tagUuid: string,
    position: number,
    text: string,
    newSegmentText: string
  ): Promise<void> {
    const segments = task.value.content.segmentsGroupedByTag;
    const affectedSegmentGroupIndex = segments.findIndex(
      (group) => group.tag.uuid === tagUuid
    );

    if (!segments[affectedSegmentGroupIndex]) {
      return;
    }

    segments[affectedSegmentGroupIndex].isLoading = true;

    const source = createUnrelatedSegmentFromTaskSegment(
      segments[affectedSegmentGroupIndex].segments[position].source,
      {
        text: newSegmentText,
      }
    );

    const destination = createUnrelatedSegmentFromTaskSegment(
      segments[affectedSegmentGroupIndex].segments[position].destination
    );

    const newSegment = { source, destination };
    segments[affectedSegmentGroupIndex].segments[position].source.text = text;

    const newSegments = segments[affectedSegmentGroupIndex].segments;
    newSegments.splice(position + 1, 0, newSegment);

    segments[affectedSegmentGroupIndex].segments = newSegments;

    await updateAllSegments(segments);
  }

  async function updateSegment(taskSegment: TaskSegments): Promise<void> {
    const segments = [...task.value.content.segmentsGroupedByTag];

    const segmentIndex = segments.findIndex(
      (segment) => segment.tag.uuid === taskSegment.tag.uuid
    );

    if (segmentIndex === -1) {
      return;
    }

    segments[segmentIndex] = taskSegment;
    const { data } = await task.value.content.updateAllSegments(segments);
    const taskContent = new TaskContent(data);

    const responseSegmentIndex = taskContent.segmentsGroupedByTag.findIndex(
      ({ tag }) => tag.uuid === taskSegment.tag.uuid
    );

    if (responseSegmentIndex !== segmentIndex) {
      task.value.content = taskContent;
      return;
    }

    task.value.content.segmentsGroupedByTag[segmentIndex] =
      taskContent.segmentsGroupedByTag[segmentIndex];
    task.value.content.adjustPositions();
  }

  async function updateAllSegments(
    taskSegments: TaskSegments[]
  ): Promise<void> {
    try {
      const { data } = await task.value.content.updateAllSegments(taskSegments);
      task.value.content = new TaskContent(data);
      task.value.content.adjustPositions();
    } catch (e) {
      Logger.log(e);

      let message = t(
        'modules.articles.notifications.updateAllSegments.failed.message'
      );

      switch ((e as { response: { status: number } })?.response?.status) {
        case 422:
          message = (e as AxiosError<LaravelErrorResponse>).response.data
            ?.message;
      }

      notification.open({
        title: t(
          'modules.articles.notifications.updateAllSegments.failed.title'
        ),
        message,
        type: 'error',
      });

      throw e;
    }
  }

  /**
   * @param {TranslationViewService} service
   * @param {number} id
   * @returns {Promise<UnwrapRef<Task>>}
   */
  async function fetch(
    service: TranslationViewService,
    id: number
  ): Promise<UnwrapRef<Task>> {
    isTaskDetailLoading.value = true;

    const { data } = await service.single(id);
    task.value = new Task({
      task: data.task,
      contentJob: new ContentJob(data.content_job),
      content: new TaskContent(data.selected_content),
      suggestions: {
        isLoading: false,
        origin: selectedSegment.value?.input,
        suggestions: data.suggestions.map(
          (suggestion) => new Suggestion(suggestion)
        ),
      },
      glossaries: {
        isLoading: false,
        glossaries: data.glossaries.map((glossary) => new Glossary(glossary)),
      },
      latest: data.can_edit_task,
    });

    languages.value = data.languages;
    articleNo.value = data.article_no;
    canEditTask.value = data.can_edit_task;
    isTaskDetailLoading.value = false;

    return task.value;
  }

  watch(selectedSegment, async (_, oldValue) => {
    if (!oldValue || typeof selectedSegment.value?.input === 'undefined') {
      return;
    }

    debounce.debounce(async () => {
      if (document.activeElement !== selectedSegment.value?.input) {
        return;
      }

      await Promise.all([fetchSuggestionsAndGlossaries(), fetchTaskContent()]);
    });
  });

  function isResponsibleUser(userId: number): boolean {
    return task.value.responsible_user_id === userId;
  }

  async function fetchTaskContent(): Promise<void> {
    try {
      isTaskDetailLoading.value = true;

      const { data } = await TaskContentService.fetchTaskContent(
        task.value.content.id
      );

      task.value.content = new TaskContent({ ...task.value.content, ...data });
    } catch (e) {
      Logger.log(e);

      notification.open({
        title: t(
          'modules.articles.notifications.suggestionsAndGlossaries.failed.title'
        ),
        message: t(
          'modules.articles.notifications.suggestionsAndGlossaries.failed.message'
        ),
        type: 'error',
      });

      throw e;
    } finally {
      isTaskDetailLoading.value = false;
    }
  }

  async function fetchSuggestionsAndGlossaries(): Promise<void> {
    try {
      const loadingState = Task.createEmpty();
      task.value.suggestions = loadingState.suggestions;
      task.value.glossaries = loadingState.glossaries;

      const { data } =
        await TaskContentService.showSuggestionsAndGlossariesBySegment(
          Number(selectedSegment.value.input.id.substring(8))
        );

      task.value.suggestions = {
        isLoading: false,
        origin: selectedSegment.value?.input,
        suggestions: data.suggestions.map(
          (suggestion) => new Suggestion(suggestion)
        ),
      };
      task.value.glossaries = {
        isLoading: false,
        glossaries: data.glossaries.map((g) => new Glossary(g)),
      };
    } catch (e) {
      const status = (e as { response: { status: number } })?.response?.status;

      if (status === 429 && (e as { isHandled?: boolean })?.isHandled) {
        return;
      }

      Logger.log(e);

      notification.open({
        title: t(
          'modules.articles.notifications.suggestionsAndGlossaries.failed.title'
        ),
        message: t(
          'modules.articles.notifications.suggestionsAndGlossaries.failed.message'
        ),
        type: 'error',
      });

      throw e;
    }
  }

  return {
    task: task as Ref<Task>,
    fetch,
    fetchSuggestionsAndGlossaries,
    fetchTaskContent,
    updateSegment,
    updateAllSegments,
    splitSegment,
    selectedSegment,
    articleNo,
    canEditTask,
    languages,
    isTaskDetailLoading,
    isResponsibleUser,
  };
});

function createUnrelatedSegmentFromTaskSegment(
  segment: TaskSegment,
  data: Partial<TaskSegmentInterface> = {}
): TaskSegment {
  return new TaskSegment({
    ...segment,
    position: 0,
    id: null,
    task_content_id: null,
    text: '',
    ...data,
  });
}
