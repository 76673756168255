export class TaskSegment implements TaskSegmentInterface {
  readonly created_at: string;
  readonly id: number;
  readonly language_tag_code: string;
  readonly meta: TaskSegmentMeta;
  readonly old_text: string;
  readonly reviewed_at: string;
  readonly status: TaskSegmentStatus;
  readonly task_content_id: number;
  readonly updated_at: string;

  text: string;
  autofill: TaskSegmentAutofill;

  public isLoading = false;

  constructor(segment: TaskSegmentInterface, isLoading = false) {
    this.created_at = segment.created_at;
    this.id = segment.id;
    this.language_tag_code = segment.language_tag_code;
    this.meta = segment.meta;
    this.old_text = segment.old_text;
    this.reviewed_at = segment.reviewed_at;
    this.status = segment.status;
    this.task_content_id = segment.task_content_id;
    this.updated_at = segment.updated_at;
    this.text = segment.text;
    this.autofill = segment.autofill;
    this.isLoading = isLoading;
  }

  get position(): number {
    return this.meta.tag.segment_position;
  }

  get hasText(): boolean {
    return this.text.trim().length > 0;
  }

  get hasNbrInText(): boolean {
    return this.text.match(/%NBR%|(?:%NBR)\d+%/i) !== null;
  }

  changeText(text: string): void {
    this.text = text;
  }
}
