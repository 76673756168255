import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { BrandPagekit } from '@/components/icons/tablericons';
import appOptions from '@/config/app.options';
import { hasProjectMembershipGuard } from '@/router/guards/hasProjectMembershipGuard';

export default [
  {
    path: '/cms',
    name: 'cms',
    component: () => import('~/pages/cms/index.vue'),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
    meta: {
      layout: SidebarLayout,
      icon: BrandPagekit,
      roles: appOptions.adminRoleNames,
    },
  },
  {
    path: '/cms/:id',
    name: 'cms.translation',
    component: () => import('~/pages/cms/[id].vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number', null),
    }),
    beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
    meta: {
      hidden: true,
      layout: SidebarLayout,
      roles: appOptions.adminRoleNames,
    },
  },
];
