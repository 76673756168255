export class Glossary implements TranslationViewGlossaryResponse {
  readonly glossary: GlossaryInterface;
  readonly glossary_term: GlossaryTerm;

  static createEmpty(): Glossary {
    return new this({
      glossary: {} as GlossaryInterface,
      glossary_term: {} as GlossaryTerm,
    });
  }

  constructor(data: TranslationViewGlossaryResponse) {
    this.glossary = data.glossary;
    this.glossary_term = data.glossary_term;
  }

  getByLanguage(language: string): GlossaryTerm {
    return this.glossary_term?.translations?.find((term) => term.language_tag_code === language);
  }
}
